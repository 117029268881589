import moment from 'moment'
import confirmPopup from '@/utility/scripts/confirm'

async function confirmLeaveOrSave() {
  if (!this.canUpdate) return

  const choice = await confirmPopup(this, {
    okText: this.$t('Global.Save'),
    title: this.$t('Global.Save'),
    text: this.$t('StaffScheduling.UnsavedChangesText'),
  })

  if (!choice) return

  await this.updateCalendar()
}

export async function toExport() {
  await confirmLeaveOrSave.call(this)

  this.$router.push({
    name: 'staff-scheduling-pdf',
    params: { timestamp: this.date, layer: this.activeLayer },
  })
}

export async function toTransmit() {
  const weekStart = moment(this.date).startOf('week').format('DD.MM.YYYY')
  const weekEnd = moment(this.date).endOf('week').format('DD.MM.YYYY')
  try {
    const wannaTransmit = await confirmPopup(this, {
      okText: this.$t('StaffScheduling.Transmit'),
      title: this.$t('StaffScheduling.Transmit'),
      text: this.$t('StaffScheduling.TransmitText', {
        time: `${weekStart} - ${weekEnd}`,
      }),
    })

    if (!wannaTransmit) return

    await confirmLeaveOrSave.call(this)

    const { data } = await this.$axios.post(
      `shift-calendar/transmit-week/${this.activeLayer}/${this.date}`,
    )
    this.calendar = data
    this.calendarCache[this.activeLayer][
      moment(this.date).startOf('week').format('YYYY-MM-DD')
    ] = data
  } catch (error) {
    this.$alert.error()
  }
}
